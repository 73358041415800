<script>
export default {
  data() {
    return {
      externalData: {},
      envApi: '',
      endPoint: '',
      loading: {}
    }
  },
  methods: {
    loadData() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
        this.envApi = envApi
      }
      this.endPoint = `/proxy/gameplay/bossevent/${this.$route.params?.id}/`
      this.$axios
        .get(envApi + this.endPoint, { params: { json: 1 } })
        .then(({ data }) => {
          this.externalData = data
        })
        .catch(this.$utils.catchError)
    },
    setTitle(apiLabel, data) {
      return `${(this.$route.query?.env || '').toUpperCase()} - ${data?.zone || ''}  : Boss event :  ${
        data?.boss_event_name || ''
      }`
    }
  }
}
</script>

<template>
  <crud-details-page
    :versioned="false"
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudUsage: false,
      crudDelete: false
    }"
    disable-top-margin
    :external-data="externalData"
    :render-title="setTitle"
    :mobile-single-column="false"
    @is-access="loadData"
  >
    <template #form>
      <h1 v-if="externalData?.s?.length">
        Date {{ externalData.s }} - {{ externalData.e }}
      </h1>
      <el-scrollbar class="w-full">
        <table class="gs-font-scaled mt-4 whitespace-nowrap align-top">
          <template
            v-for="(rowData, row) in externalData.groups"
            :key="row"
          >
            <tr>
              <th class="text-xl text-left">
                Tier {{ row }}
              </th>
            </tr>
            <tr>
              <td
                v-for="(groupData, td) in rowData"
                :key="td"
                class="gs-related-px-sm pb-4 align-top"
              >
                <div>
                  <div class="gs-related-py-xs font-semibold">
                    Group #{{ groupData.group_no }}
                    <span v-if="groupData.battle_id">(BattleId: {{ groupData.battle_id }})</span>
                    <span
                      v-if="groupData.battle_id && groupData?.fighters?.[0]?.started !== false"
                      class="pl-2 text-red-500"
                    >(Score: {{ groupData.score_sum }})</span>
                    <span
                      v-if="groupData?.fighters?.[0]?.started !== false"
                      class="pl-2 text-green-700"
                    >started</span>
                    <span
                      v-else
                      class="pl-2 text-red-500"
                    >
                      not started
                    </span>
                  </div>
                  <div
                    v-for="(player, indexPlayer) in groupData.fighters"
                    :key="indexPlayer"
                  >
                    #{{ player.position }}
                    {{ player.name }}
                    {{ player.id }}
                    <span
                      v-if="player.score"
                      class="pl-2 font-bold text-red-500"
                    >
                      Score {{ player.score }}
                    </span>
                    <span
                      v-if="player.revenue"
                      class="font-bold"
                    >
                      Revenue ${{ player.revenue }}
                    </span>
                    {{ player.guild_name }}
                    <span
                      v-if="player.is_bot"
                      class="font-bold"
                      style="color: orange"
                    >
                      BOT
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <tr>
            <th class="text-xl text-left">
              Player ranking
            </th>
            <th
              v-if="externalData?.ranking_guild"
              class="text-xl text-left"
            >
              Guild ranking
            </th>
          </tr>
          <tr>
            <td>
              <div
                v-for="(player, indexPlayer) in externalData.ranking"
                :key="indexPlayer"
              >
                #{{ indexPlayer + 1 }}
                {{ player.name }}
                {{ player.id }}
                <span
                  v-if="player.score"
                  class="pl-2 font-bold text-red-500"
                >
                  Score {{ player.score }}
                </span>
                <span
                  v-if="player.revenue"
                  class="font-bold"
                >
                  Revenue ${{ player.revenue }}
                </span>
                {{ player.guild_name }}
                <span
                  v-if="player.is_bot"
                  class="font-bold"
                  style="color: orange"
                >
                  BOT
                </span>
              </div>
            </td>
            <td>
              <div
                v-for="(guild, indexGuild) in externalData.ranking_guild"
                :key="indexGuild"
              >
                #{{ indexGuild + 1 }}
                {{ guild.name }}
                <span
                  v-if="guild.score"
                  class="pl-2 font-bold text-red-500"
                >
                  Score {{ guild.score }}
                </span>
              </div>
            </td>
          </tr>
        </table>
      </el-scrollbar>
    </template>
  </crud-details-page>
</template>
