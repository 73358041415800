<script setup>
import { ref } from 'vue'
import CrudFieldSelectorForgeReceipt from '@/components/forms/crud-fields/CrudFieldSelectorForgeReceipt.vue'
import { globalProperties as app } from '!/plugins/utilities'

function initItem(form) {
  form.simChestIncomeCount = 100
  form.simChestIncomeSimulations = 25
  return form
}
const loading = ref({})
function simChest(form) {
  loading.value.simChest = true
  app.$axios
    .get(`/admin/api/proxy/request/`, {
      params: {
        data: [],
        env: 'dev',
        chest_id: form.Versioned.Root,
        count: form.simChestIncomeCount,
        simulations: form.simChestIncomeSimulations,
        uri: '/proxy/simchest/',
        method: 'GET'
      }
    })
    .then(({ data }) => {
      app.$messageBox
        .confirm(data.logs, undefined, {
          confirmButtonText: 'OK',
          type: 'info',
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          icon: 'none',
          customClass: 'md:max-w-screen-md'
        })
        .catch(() => {})
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value.simChest = false
    })
}

function simChestIncome(form) {
  loading.value.simChestIncome = true
  app.$axios
    .get(`/admin/api/proxy/request/`, {
      params: {
        data: [],
        env: 'dev',
        chest_id: form.Versioned.Root,
        count: form.simChestIncomeCount,
        simulations: form.simChestIncomeSimulations,
        uri: '/proxy/simchestincome/',
        method: 'GET'
      }
    })
    .then(({ data }) => {
      app.$messageBox
        .confirm(data.logs, undefined, {
          confirmButtonText: 'OK',
          type: 'info',
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          icon: 'none',
          customClass: 'md:max-w-screen-md'
        })
        .catch(() => {})
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value.simChestIncome = false
    })
}
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    :render-init-item="initItem"
  >
    <template #form="{ form }">
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ChestType"
          :visible-options="['Spin']"
          required
        />
        <!-- ForgeReceiptForBI -->
        <CrudFieldSelectorForgeReceipt
          api-field-name="ForgeReceiptForBI"
          label="Forge receipt for BI"
        />
        <!-- UseShopBanner -->
        <crud-field-switcher api-field-name="UseShopBanner" />
      </fields-col>
      <fields-col :sm="4">
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
        <!-- Price1 -->
        <crud-field-number api-field-name="Price1" />
        <!-- Price10 -->
        <crud-field-number api-field-name="Price10" />
        <!-- PriceDaily -->
        <crud-field-number api-field-name="PriceDaily" />
        <!-- PriceSpecial -->
        <crud-field-number api-field-name="PriceSpecial" />
      </fields-col>
      <fields-col :sm="7">
        <!-- AssetShopPreview -->
        <crud-field-selector-files api-field-name="AssetShopPreview" />
        <!-- AssetBackground -->
        <crud-field-selector-files api-field-name="AssetBackground" />
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
        <!-- AssetChestPreview -->
        <crud-field-selector-files
          api-field-name="AssetChestPreview"
          label="AssetPopup"
        />
        <!-- AssetSeasonBanner -->
        <crud-field-selector-files
          api-field-name="AssetSeasonBanner"
          label="AssetShopBanner"
        />
      </fields-col>
      <fields-col :sm="7">
        <!-- AssetForgeTier1 -->
        <crud-field-selector-files api-field-name="AssetForgeTier1" />
        <!-- AssetForgeTier2 -->
        <crud-field-selector-files api-field-name="AssetForgeTier2" />
        <!-- AssetForgeTier3 -->
        <crud-field-selector-files api-field-name="AssetForgeTier3" />
      </fields-col>
      <section-divider>Loot settings</section-divider>
      <fields-col :sm="8">
        <!-- DuplicatedDrawEnergyCommon -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyCommon" />
        <!-- DuplicatedDrawEnergyUncommon -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyUncommon" />
        <!-- DuplicatedDrawEnergyRare -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyRare" />
        <!-- DuplicatedDrawEnergyEpic -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyEpic" />
        <!-- DuplicatedDrawEnergyLegendary -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyLegendary" />
      </fields-col>
      <fields-col :sm="8">
        <!-- DuplicatedDrawEnergyCommon -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsCommon" />
        <!-- DuplicatedDrawEnergyUncommon -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsUncommon" />
        <!-- DuplicatedDrawEnergyRare -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsRare" />
        <!-- DuplicatedDrawEnergyEpic -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsEpic" />
        <!-- DuplicatedDrawEnergyLegendary -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsLegendary" />
      </fields-col>
      <fields-col :sm="8">
        <!-- WeightCommon -->
        <crud-field-number api-field-name="WeightCommon" />
        <!-- WeightUncommon -->
        <crud-field-number api-field-name="WeightUncommon" />
        <!-- WeightRare -->
        <crud-field-number api-field-name="WeightRare" />
        <!-- WeightEpic -->
        <crud-field-number api-field-name="WeightEpic" />
        <!-- WeightLegendary -->
        <crud-field-number api-field-name="WeightLegendary" />
        <!-- WeightEnergy1 -->
        <crud-field-number api-field-name="WeightEnergy1" />
        <!-- WeightEnergy2 -->
        <crud-field-number api-field-name="WeightEnergy2" />
        <!-- WeightEnergy3 -->
        <crud-field-number api-field-name="WeightEnergy3" />
      </fields-col>
      <section-divider>Loots</section-divider>
      <el-col>
        <el-card
          shadow="never"
          class="gs-font-scaled relative mt-8 mb-10"
        >
          <fields-col>
            <crud-field-slot
              :sm="4"
              label="Sim Chest"
              empty-label
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChest"
                @click="simChest(form)"
              >
                Sim spin
              </el-button>
            </crud-field-slot>
            <crud-field-slot
              label="Sim Chest Income"
              empty-label
              class="flex-none"
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChestIncome"
                @click="simChestIncome(form)"
              >
                Sim spin income
              </el-button>
            </crud-field-slot>
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeCount"
              label="Number of spins"
            />
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeSimulations"
              label="Number of simulations"
            />
          </fields-col>
          <span class="font-related-xs absolute bottom-2 left-4 text-neutral-500">before sim: save & do CC DEV</span>
        </el-card>
      </el-col>
      <!-- ReplacedSkinSlotNumber -->
      <crud-field-number
        :sm="8"
        api-field-name="ReplacedSkinSlotNumber"
        min="0"
        max="55"
      />
      <!-- ReplacedSkin -->
      <crud-field-selector-skin
        :sm="8"
        api-field-name="ReplacedSkin"
      />
      <!-- ReplacedSkinQuantity -->
      <crud-field-number
        :sm="8"
        api-field-name="ReplacedSkinQuantity"
      />
      <template
        v-for="i in 55"
        :key="i"
      >
        <crud-field-selector-skin
          :sm="10"
          :api-field-name="`Skin${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Quantity${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Weight${i}`"
        />
        <crud-field-switcher
          :sm="6"
          :api-field-name="`IsPreview${i}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
