<script>
import CrudFieldSelectorChest from '@/components/forms/crud-fields/CrudFieldSelectorChest.vue'
import CrudFieldSelectorBossEvent from '@/components/forms/crud-fields/CrudFieldSelectorBossEvent.vue'
import CrudFieldSelectorTournament from '@/components/forms/crud-fields/CrudFieldSelectorTournament.vue'
import CrudFieldSelectorForgeReceipt from '@/components/forms/crud-fields/CrudFieldSelectorForgeReceipt.vue'
import CrudFieldSelectorAchievementGroup from '@/components/forms/crud-fields/CrudFieldSelectorAchievementGroup.vue'
import CrudFieldSelectorCardEvent from '@/components/forms/crud-fields/CrudFieldSelectorCardEvent.vue'
import CrudFieldSelectorCardExchangeEvent from '@/components/forms/crud-fields/CrudFieldSelectorCardExchangeEvent.vue'
import CrudFieldSelectorCustomDice from '@/components/forms/crud-fields/CrudFieldSelectorCustomDice.vue'
import CrudFieldSelectorHitThreeEvent from '@/components/forms/crud-fields/CrudFieldSelectorHitThreeEvent.vue'
import CrudFieldSelectorPveChallengeEvent from '@/components/forms/crud-fields/CrudFieldSelectorPveChallengeEvent.vue'
import CrudFieldSelectorLotteryEvent from '@/components/forms/crud-fields/CrudFieldSelectorLotteryEvent.vue'

export default {
  components: {
    CrudFieldSelectorLotteryEvent,
    CrudFieldSelectorCardEvent,
    CrudFieldSelectorAchievementGroup,
    CrudFieldSelectorForgeReceipt,
    CrudFieldSelectorTournament,
    CrudFieldSelectorBossEvent,
    CrudFieldSelectorChest,
    CrudFieldSelectorCardExchangeEvent,
    CrudFieldSelectorCustomDice,
    CrudFieldSelectorHitThreeEvent,
    CrudFieldSelectorPveChallengeEvent
  }
}
</script>

<template>
  <crud-details-page
    api="season"
    disable-top-margin
  >
    <template #form>
      <!-- No -->
      <crud-field-number
        :sm="8"
        api-field-name="No"
        required
      />
      <!-- Name -->
      <crud-field-text
        :sm="8"
        api-field-name="Name"
        required
      />
      <!-- NameTag -->
      <crud-field-selector-client-strings
        :sm="8"
        api-field-name="NameTag"
      />
      <section-divider>Settings</section-divider>
      <fields-col :sm="8">
        <!-- SeasonBackground -->
        <crud-field-selector-files api-field-name="SeasonBackground" />
        <!-- SeasonHubBackground -->
        <crud-field-selector-files api-field-name="SeasonHubBackground" />
        <!-- RewardBackground -->
        <crud-field-selector-files
          api-field-name="RewardBackground"
          label="Season pass banner"
        />
        <!-- HomeBackground -->
        <crud-field-selector-files api-field-name="HomeBackground" />
        <!-- SeasonPassBackground -->
        <crud-field-selector-files api-field-name="SeasonPassBackground" />
      </fields-col>
      <fields-col :sm="8">
        <!-- SeasonStartPopupBackground -->
        <crud-field-selector-files api-field-name="SeasonStartPopupBackground" />
        <!-- SeasonStartPopupCustomTitle -->
        <crud-field-selector-files api-field-name="SeasonStartPopupCustomTitle" />
        <!-- GuildTrophyFile -->
        <crud-field-selector-files api-field-name="GuildTrophyFile" />
        <!-- PlayerTrophyFile -->
        <crud-field-selector-files api-field-name="PlayerTrophyFile" />
      </fields-col>
      <!-- SeasonCalendar -->
      <crud-field-select
        :sm="8"
        api-field-name="SeasonCalendar"
        options-enum="enums.SeasonCalendar"
      />
      <template v-if="!$isTheGang">
        <section-divider>Home Backgrounds</section-divider>
        <crud-field-selector-files
          v-for="i in 3"
          :key="i"
          :sm="8"
          :api-field-name="`HomeCustomBackground${i}`"
        />
        <crud-field-number
          v-for="i in 3"
          :key="i"
          :sm="8"
          :api-field-name="`HomeCustomBackground${i}SeasonDayStart`"
        />
        <fields-col :sm="8" />
        <crud-field-number
          v-for="i in 3"
          :key="i"
          :sm="8"
          :api-field-name="`HomeCustomBackground${i}DurationHours`"
        />
      </template>
      <section-divider>Chests</section-divider>
      <template
        v-for="i in 8"
        :key="i"
      >
        <CrudFieldSelectorChest
          :sm="8"
          :api-field-name="`Chest${i}`"
        />
        <crud-field-selector-files
          v-if="i === 1"
          :sm="8"
          api-field-name="ChestKeyIcon"
        />
        <crud-field-selector-skin
          v-else
          :sm="8"
          :api-field-name="`Chest${i}Key`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Chest${i}SeasonDayStart`"
          :label="`SeasonDayStart${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Chest${i}DurationDays`"
          :label="`DurationDays${i}`"
        />
      </template>
      <section-divider>Event battles</section-divider>
      <fields-col :sm="14">
        <template
          v-for="i in 4"
          :key="i"
        >
          <CrudFieldSelectorBossEvent
            :sm="16"
            :api-field-name="`BossEvent${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`BossEvent${i}StartSeasonDay`"
          />
        </template>
      </fields-col>
      <section-divider>Tournaments</section-divider>
      <fields-col :sm="14">
        <template
          v-for="i in 3"
          :key="i"
        >
          <CrudFieldSelectorTournament
            :sm="16"
            :api-field-name="`Tournament${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Tournament${i}StartSeasonDay`"
          />
        </template>
      </fields-col>
      <section-divider>Achievements Groups</section-divider>
      <template
        v-for="i in 10"
        :key="i"
      >
        <CrudFieldSelectorAchievementGroup
          :sm="8"
          :api-field-name="`AchievementsGroup${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`AchievementsGroup${i}SeasonDayStart`"
          :label="`Season day start ${i}`"
        />
        <crud-field-selector-client-strings
          :sm="6"
          :api-field-name="`AchievementsGroup${i}StartNotification`"
          :label="`Group ${i} Start Notification`"
        />
        <crud-field-selector-client-strings
          :sm="6"
          :api-field-name="`AchievementsGroup${i}EndNotification`"
          :label="`Group ${i} End Notification`"
        />
      </template>
      <template v-if="$isTheGang">
        <section-divider>Card Events</section-divider>
        <fields-col :sm="14">
          <template
            v-for="i in 3"
            :key="i"
          >
            <CrudFieldSelectorCardEvent
              :sm="16"
              :api-field-name="`CardEvent${i}`"
            />
            <crud-field-number
              :sm="8"
              :api-field-name="`CardEvent${i}SeasonDayStart`"
              :label="`Season day start ${i}`"
            />
          </template>
        </fields-col>
      </template>
      <template v-if="$isTheGang">
        <section-divider>Card Exchange Events</section-divider>
        <template
          v-for="i in 20"
          :key="i"
        >
          <CrudFieldSelectorCardExchangeEvent
            :sm="8"
            :api-field-name="`CardExchangeEvent${i}`"
          />
          <crud-field-number
            :sm="4"
            :api-field-name="`CardExchangeEvent${i}SeasonDayStart`"
            :label="`Season day start ${i}`"
          />
          <crud-field-number
            :sm="7"
            :api-field-name="`CardExchangeEvent${i}SeasonDayHour`"
            :label="`Season day hour ${i}`"
            :vertical="$windowWidth < 640"
          >
            <template #fieldAppend>
              <div class="pl-1 pt-1 text-green-600">
                EUROPE ZONE TIME
              </div>
            </template>
          </crud-field-number>
        </template>
      </template>
      <template v-if="$isTheGang">
        <section-divider>Lottery Events</section-divider>
        <fields-col :sm="14">
          <template
            v-for="i in 4"
            :key="i"
          >
            <CrudFieldSelectorLotteryEvent
              :sm="16"
              :api-field-name="`LotteryEvent${i}`"
            />
            <crud-field-number
              :sm="8"
              :api-field-name="`LotteryEvent${i}SeasonDayStart`"
            />
          </template>
        </fields-col>
      </template>
      <section-divider>Hit Three Events</section-divider>
      <fields-col :sm="14">
        <template
          v-for="i in 11"
          :key="i"
        >
          <CrudFieldSelectorHitThreeEvent
            :sm="16"
            :api-field-name="`HitThreeEvent${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`HitThreeEvent${i}SeasonDayStart`"
            :label="`Season day start ${i}`"
          />
        </template>
      </fields-col>
      <template v-if="$isTheGang">
        <section-divider>Pve Challenge Events</section-divider>
        <template
          v-for="i in 3"
          :key="i"
        >
          <CrudFieldSelectorPveChallengeEvent
            :sm="8"
            :api-field-name="`PveChallengeEvent${i}`"
          />
          <crud-field-number
            :sm="6"
            :api-field-name="`PveChallengeEvent${i}SeasonDayStart`"
          />
          <crud-field-number
            :sm="6"
            :api-field-name="`PveChallengeEvent${i}DurationDays`"
          />
        </template>
      </template>
      <template v-if="!$isTheGang">
        <section-divider>Forge Receipts</section-divider>
        <template
          v-for="i in 3"
          :key="i"
        >
          <CrudFieldSelectorForgeReceipt
            :sm="8"
            :api-field-name="`ForgeReceipt${i}`"
          />
          <crud-field-number
            :sm="6"
            :api-field-name="`ForgeReceipt${i}SeasonDayStart`"
          />
          <crud-field-number
            :sm="6"
            :api-field-name="`ForgeReceipt${i}DurationDays`"
          />
        </template>
      </template>
      <template v-if="!$isTheGang">
        <section-divider>Unlimited Roll Events</section-divider>
        <template
          v-for="i in 3"
          :key="i"
        >
          <CrudFieldSelectorUnlimitedRollEvent
            :sm="8"
            :api-field-name="`UnlimitedRollEvent${i}`"
          />
          <crud-field-number
            :sm="6"
            :api-field-name="`UnlimitedRollEvent${i}SeasonDayStart`"
          />
          <crud-field-number
            :sm="6"
            :api-field-name="`UnlimitedRollEvent${i}DurationDays`"
          />
        </template>
      </template>
      <section-divider>Custom Dices</section-divider>
      <fields-col :sm="14">
        <template
          v-for="i in 7"
          :key="i"
        >
          <CrudFieldSelectorCustomDice
            :sm="16"
            :api-field-name="`CustomDice${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`CustomDice${i}SeasonDayStart`"
            :label="`Season day start ${i}`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
