<script setup>
</script>

<template>
  <crud-details-page
    api="rewards/hit-three-event-global-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col
        v-for="i in 10"
        :key="i"
      >
        <section-divider>Reward {{ i }}</section-divider>
        <fields-col :sm="4">
          <crud-field-number
            :api-field-name="`Reward${i}.PlacesStart`"
            label="PlacesStart"
          />
          <crud-field-number
            :api-field-name="`Reward${i}.PlacesEnd`"
            label="PlacesEnd"
          />
          <!-- SeasonPassPoints -->
          <crud-field-number
            :api-field-name="`Reward${i}.SeasonPassPoints`"
            label="SeasonPassPoints"
          />
        </fields-col>
        <fields-col :sm="4">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`Reward${i}.XpPct`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`Reward${i}.Energy`"
            label="Energy"
          />
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`Reward${i}.Gems`"
            label="Gems"
          />
        </fields-col>
        <fields-col :sm="8">
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Reward${i}.Skin1`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Reward${i}.Skin1Amount`"
              label="Skin amount"
            />
          </fields-col>
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Reward${i}.Skin2`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Reward${i}.Skin2Amount`"
              label="Skin amount"
            />
          </fields-col>
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Reward${i}.Skin3`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Reward${i}.Skin3Amount`"
              label="Skin amount"
            />
          </fields-col>
        </fields-col>
        <fields-col :sm="8">
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Reward${i}.Skin4`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Reward${i}.Skin4Amount`"
              label="Skin amount"
            />
          </fields-col>
          <fields-col :sm="16">
            <crud-field-selector-skin
              :api-field-name="`Reward${i}.Skin5`"
              label="Skin"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Reward${i}.Skin5Amount`"
              label="Skin amount"
            />
          </fields-col>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
