<script>
export default {
  data() {
    const apiFormKeys = ['default_message_expire_time_days']
    return {
      apiFormKeys,
      selectedTextarea: 'Contents',
      localForm: {
        tags: 'Player Name - <PLAYER>'
      },
      localExternalErrors: {}
    }
  },
  methods: {
    insertImage(args, form) {
      if (typeof form[this.selectedTextarea] === 'undefined') {
        form[this.selectedTextarea] = ''
      } else {
        form[this.selectedTextarea] += '\n'
      }
      form[this.selectedTextarea] += `[file=${args.ID}]`
      this.$utils.nextLoopEvent().then(() => {
        form.insertImageModel = null
      })
    },
    setTextareaFocus(c) {
      this.selectedTextarea = c
    }
  }
}
</script>

<template>
  <crud-details-page
    api="tools/template-global-message"
    :api-form-keys="apiFormKeys"
    :versioned="false"
  >
    <template #form="{ form }">
      <!-- Title -->
      <crud-field-text
        :sm="8"
        api-field-name="Title"
        required
      />
      <!-- TitleTag -->
      <crud-field-selector-client-strings
        :sm="8"
        api-field-name="TitleTag"
        required
      />
      <!-- CreatedAt -->
      <crud-field-date-time
        :sm="5"
        api-field-name="CreatedAt"
        required
        col-break
      />
      <!-- Sender -->
      <crud-field-text
        :sm="8"
        api-field-name="Sender"
        required
      />
      <!-- SenderTag -->
      <crud-field-selector-client-strings
        :sm="8"
        api-field-name="SenderTag"
        required
      />
      <crud-field-switcher
        api-field-name="Sent"
        class="flex-none"
        col-break
      />
      <!-- XpPct -->
      <crud-field-number
        :sm="4"
        api-field-name="XpPct"
      />
      <!-- Energy -->
      <crud-field-number
        :sm="4"
        api-field-name="Energy"
      />
      <!-- Gems -->
      <crud-field-number
        :sm="4"
        api-field-name="Gems"
      />
      <!-- DuplicatePoints -->
      <crud-field-number
        :sm="4"
        api-field-name="DuplicatePoints"
      />
      <!-- SeasonPassPoints -->
      <crud-field-number
        :sm="4"
        api-field-name="SeasonPassPoints"
      />
      <!-- AttackPointsGift -->
      <crud-field-number
        :sm="4"
        api-field-name="AttackPointsGift"
        col-break
      />
      <crud-field-number
        :sm="4"
        api-field-name="MinPlayerLevel"
      />
      <crud-field-number
        :sm="4"
        api-field-name="CustomExpireTimeDays"
        label="Custom Expire Days"
        :placeholder="form?.default_message_expire_time_days ? `Default ${form.default_message_expire_time_days} days` : ``"
      />
      <crud-field-number
        :sm="4"
        api-field-name="ForLoggedInDays"
      />
      <crud-field-switcher
        :sm="4"
        api-field-name="ShowOnStart"
      />
      <crud-field-selector-files
        :sm="4"
        api-field-name="AssetHeader"
        col-break
      />
      <template
        v-for="i in 5"
        :key="i"
      >
        <crud-field-selector-skin
          :sm="8"
          :api-field-name="`Skin${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Amount${i}`"
          col-break
        />
      </template>
      <section-divider>Translation</section-divider>
      <fields-col :sm="12">
        <crud-field-selector-files
          api-field-name="insertImageModel"
          label="Insert Image"
          :init-filters="{ Group: 'Messages' }"
          vertical
          @change="insertImage($event, form)"
        >
          <template #fieldAppend>
            <div class="text-black-600 pl-1">
              max image width = <strong>520px</strong>
            </div>
          </template>
        </crud-field-selector-files>
      </fields-col>
      <crud-field-textarea
        :textarea-rows="5"
        api-field-name="Contents"
        label="EN"
        required
        @focus="setTextareaFocus('Contents')"
      />
      <crud-field-textarea
        v-for="field in $store.getters['auth/userLanguages']"
        :key="field"
        :textarea-rows="5"
        :api-field-name="`Contents${field.toUpperCase()}`"
        :label="field.toUpperCase()"
        @focus="setTextareaFocus(`Contents${field.toUpperCase()}`)"
      />
      <crud-field-textarea
        api-field-name="tags"
        disabled
        label="Tags"
        :form="localForm"
        :external-errors="localExternalErrors"
      />
      <section-divider>Auto sending options</section-divider>
      <crud-field-number
        :sm="6"
        api-field-name="SeasonNo"
      />
      <crud-field-number
        :sm="6"
        api-field-name="SeasonDay"
      />
      <crud-field-number
        :sm="6"
        api-field-name="SeasonHour"
      />
      <crud-field-number
        :sm="6"
        api-field-name="SeasonMinute"
      />
      <section-divider>Zones</section-divider>
      <crud-field-switcher
        api-field-name="ForAsia"
        class="flex-none"
      />
      <crud-field-switcher
        api-field-name="ForEurope"
        class="flex-none"
      />
      <crud-field-switcher
        api-field-name="ForAmerica"
        class="flex-none"
      />
    </template>
  </crud-details-page>
</template>
